@import "@/styles/colors.module";

.header {
  height: 96px;
  padding: 32px 64px;
  background-color: $white;

  .title {
    min-width: 402px;
    font-size: 32px;
    line-height: 28px;
    font-weight: 600;
  }

  .searchInput {
    max-width: 468px;
  }
}